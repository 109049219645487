<!--考勤规则页面日度表格  -->
<template>
  <div class="">
    <!-- table模块 -->
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      :searchActions="searchActions"
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :clickable="false"
      :showToolBar="true"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
    </ax-table>
  </div>
</template>
  
  <script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "searchText",
    options: { placeholder: "请输入规则名称..." },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      api,
      columns: this.$ax.tools.initColumn([
        {
          title: "规则名称",
          dataIndex: "residentName",
          ellipsis: false,
          width: 120,
        },
        {
          title: "规则类型",
          dataIndex: "gender",
          ellipsis: false,
          width: 80,
        },
        {
          title: "打卡时间",
          dataIndex: "identityCard",
          ellipsis: true,
          width: 300,
        },
        {
          title: "打卡位置",
          dataIndex: "age",
          ellipsis: false,
          width: 120,
        },
        {
          title: "打开wifi",
          dataIndex: "nation_dictText",
          ellipsis: true,
          width: 100,
        },

        {
          title: "打卡人数",
          dataIndex: "educationalLevel_dictText",
          ellipsis: false,
          width: 120,
        },
      ]),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "searchlist", text: "查询" },
        { name: "reset", text: "重置" },
      ],

      dataSourceParams: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
  <style lang='less' scoped>
/deep/.ant-menu-item-selected {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}

/deep/.ant-menu-item:hover {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.green {
  background-color: #f6ffed;
  color: #389e0d;
  border-radius: 4px;
  border: #f6ffed;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>